import styled from 'styled-components';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { HeroBlock } from '@whitelabel/component-library';
import Layout from '../Layout';
import { StyledMain } from '../Layout/styledLayout';
import { StyledSectionNav } from '../Layout/FooterLarge/styledFooterLarge';

export const StyledLayout = styled(Layout)`
  ${StyledMain} {
    margin-bottom: 0;
  }

  ${StyledSectionNav} {
    border-top: 0;
  }
`;

export const StyledHeroBlock = styled(HeroBlock)`
  margin-top: ${multiply(spacer, -1.5)};

  ${mediaBreakpointUp('sm')`
    margin-top: ${multiply(spacer, -2)};
  `}
  ${mediaBreakpointUp('lg')`
    margin-top: ${multiply(spacer, -3)};
`}
`;
