import styled from 'styled-components';
import { NavbarBrand } from 'reactstrap';
import { hideVisually } from 'polished';
import { INavBrandProps } from '@whitelabel/xcover-www-frontend/src/helpers/types';

export const StyledBrand = styled(NavbarBrand)`
  padding: 0;
  border-bottom: 0;

  &:hover {
    background-color: inherit;
  }
`;

export const StyledBrandLogo = styled.img<{ size: INavBrandProps['size'] }>`
  display: block;
  width: auto;
  max-height: ${({ size }) => (size === 'lg' ? '4rem' : '2rem')};
`;

export const StyledBrandTitle = styled.span`
  ${hideVisually()}
`;
