import React, { ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { INavItem } from '@whitelabel/xcover-www-frontend/src/helpers/types';
import LinkTo from '@whitelabel/xcover-shared/components/LinkTo';
import messages from '../messages';
import { StyledNavItem, StyledNavLink, StyledNavIcon } from './styledNavItem';

interface INavItemProps {
  item: INavItem;
  onClick?: ReactEventHandler;
  className?: string;
  hideOnMobileAndTablet?: boolean;
}

const NavItem = ({
  item: { messageKey, name, path, activePaths, icon, tag = LinkTo },
  onClick,
  className,
  hideOnMobileAndTablet,
}: INavItemProps): JSX.Element => {
  const intl = useIntl();
  const router = useRouter();
  return (
    <StyledNavItem data-test-id="NavItem" className={className} $hideOnMobileAndTablet={hideOnMobileAndTablet}>
      <StyledNavLink
        tag={tag}
        href={`/${intl.locale}${path}`}
        active={
          activePaths ? !!activePaths.find((activePath: string) => router.pathname.startsWith(activePath)) : undefined
        }
        data-test-id="NavItem-link"
        data-analytics={`navbar${messageKey || name}`}
        onClick={onClick}
      >
        {icon ? <StyledNavIcon as={icon} /> : ''}
        {messageKey ? intl.formatMessage(messages[messageKey as keyof typeof messages]) : name}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default NavItem;
