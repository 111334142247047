import React from 'react';
import dynamic, { LoaderComponent } from 'next/dynamic';
import { useIntl } from 'react-intl';
import { retry } from '@whitelabel/helpers/utils';
import { StyledLayout, StyledHeader, StyledMain, StyledLoading } from './styledLayout';
import { IFooterBasicProps } from './Footer/Footer';

const Footer = dynamic<IFooterBasicProps>(
  () => retry(() => import('./Footer' /* webpackChunkName: "footer_component" */)) as LoaderComponent,
  {
    loading: StyledLoading,
    ssr: false,
  },
);

interface ILayoutProps {
  navBar?: React.ReactNode;
  footerType?: 'slim' | 'large';
  disclaimer?: string;
  disclaimerSlug?: string;
  hideDisclaimer?: boolean;
  gray?: boolean;
  spacing?: 'lg';
  className?: string;
  children?: React.ReactNode;
  footer?: boolean | React.ReactElement;
  hideFooterNav?: boolean;
}

const Layout = ({
  navBar,
  footerType = 'large',
  disclaimer,
  disclaimerSlug = 'disclaimer',
  hideDisclaimer,
  gray,
  spacing,
  className,
  children,
  footer,
  hideFooterNav,
}: ILayoutProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledLayout $rtl={intl.bidi} $gray={gray} className={className} data-test-id="Layout">
      {navBar && (
        <StyledHeader id="Header" $spacing={spacing} data-test-id="Header">
          {navBar}
        </StyledHeader>
      )}

      <StyledMain id="Main" role="main" $spacing={spacing} data-test-id="Main">
        {children}
      </StyledMain>

      {footer ||
        (footer !== false && (
          <Footer
            footertype={footerType}
            hidedisclaimer={hideDisclaimer ? 'true' : 'false'}
            hidefooternav={hideFooterNav ? 'true' : 'false'}
            disclaimer={disclaimer}
            disclaimerSlug={disclaimerSlug}
          />
        ))}
    </StyledLayout>
  );
};

export default Layout;
