import styled from 'styled-components';
import { Loading } from '@whitelabel/component-library';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledLoading = styled(Loading)`
  margin: 4rem 0;

  ${mediaBreakpointUp('md')`
  margin: 5rem 0;
`}

  ${mediaBreakpointUp('lg')`
  margin: 8rem 0;
`}
`;
