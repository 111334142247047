export const ACCOUNT_NAV_ITEMS = [
  {
    key: 'account',
    messageKey: 'accountLink',
    name: 'Your Policies',
    path: '/account',
    activePaths: ['/coi/', '/modify/', '/pds/', '/notify/'],
    tag: 'a' as React.ElementType,
  },
  {
    key: 'makeClaim',
    messageKey: 'makeClaimLink',
    name: 'Your Claims',
    path: '/claims/',
    tag: 'a' as React.ElementType,
  },
];
