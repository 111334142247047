import { ReactComponent as IconCustomer } from '@whitelabel/media/icons/global/icon-16-customer.svg';
import { ReactComponent as IconHelp } from '@whitelabel/media/icons/global/icon-16-information.svg';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';

export const customerNavItems = [
  {
    key: 'accountDropdown',
    messageKey: 'accountGlobalNavDropdownLink',
    path: '/account',
    name: 'account',
    tag: 'a' as React.ElementType,
  },
  {
    key: 'claimsDropdown',
    messageKey: 'claimsGlobalNavDropdownLink',
    path: '/claims/',
    name: 'claim',
    tag: 'a' as React.ElementType,
  },
  {
    key: 'profileDropdown',
    messageKey: 'profileGlobalNavDropdownLink',
    path: '/profile',
    name: 'profile',
    tag: 'a' as React.ElementType,
  },
];

export const customerDesktopNavItems = [
  {
    key: 'profileDropdown',
    messageKey: 'profileLink',
    path: '/profile',
    name: 'profile',
    tag: 'a' as React.ElementType,
  },
];

export const protectionNavItems = [
  {
    key: 'product',
    messageKey: 'productHeading',
    name: 'Product',
    path: '/',
    children: [
      {
        key: 'electronics',
        messageKey: 'electronicsLink',
        name: 'Electronics',
        path: '/electronics-protection',
      },
      {
        key: 'Appliances',
        messageKey: 'appliancesLink',
        name: 'Appliances',
        path: '/appliances-protection',
      },
      {
        key: 'Furniture',
        messageKey: 'furnitureLink',
        name: 'Furniture',
        path: '/furniture-protection',
      },
      {
        key: 'Jewelry',
        messageKey: 'jewelryLink',
        name: 'Jewelry',
        path: '/jewelry-protection',
      },
      {
        key: 'Sports Equipment',
        messageKey: 'sportsEquipmentLink',
        name: 'Sports Equipment',
        path: '/sports-equipment-protection',
      },
      {
        key: 'Eyewear',
        messageKey: 'eyewearLink',
        name: 'Eyewear',
        path: '/eyewear-protection',
      },
    ],
  },
  {
    key: 'business',
    messageKey: 'businessHeading',
    name: 'Business',
    path: '/',
    children: [
      {
        key: 'Shake Shield',
        messageKey: 'shakeShieldLink',
        name: 'Shake Shield',
        path: '/shake-shield',
      },
    ],
  },
  {
    key: 'travel',
    messageKey: 'travelHeading',
    name: 'Travel',
    path: '/',
    children: [
      {
        key: 'travel',
        messageKey: 'travelLink',
        name: 'Travel',
        path: '/travel-protection',
      },
    ],
  },
  {
    key: 'other',
    messageKey: 'otherHeading',
    name: 'Other',
    path: '/',
    children: [
      {
        key: 'Ticket & Events',
        messageKey: 'ticketEventsLink',
        name: 'Ticket & Events',
        path: '/tickets-and-events-protection',
      },
    ],
  },
];

export const globalNavHelp = {
  key: 'help',
  messageKey: 'helpGlobalNavLink',
  path: '/help',
  name: 'Help',
  icon: IconHelp,
  tag: 'a' as React.ElementType,
};

export const globalNavLogin = {
  key: 'login',
  messageKey: 'loginGlobalNavLink',
  name: 'Login',
  path: '/login',
  icon: IconCustomer,
  tag: 'a' as React.ElementType,
};

export const globalNavAccount = {
  key: 'account',
  messageKey: 'accountGlobalNavLink',
  path: '/account',
  name: 'Account',
  icon: IconCustomer,
  tag: 'a' as React.ElementType,
};

export const navProtection = {
  key: 'protection',
  messageKey: 'protectionNavLink',
  path: '/',
  name: 'Protection',
  icon: IconChevronDown,
};

export const navAPIAffiliates = {
  key: 'apiAffiliates',
  messageKey: 'apiAffiliatesLink',
  path: '/',
  name: 'API & Affiliates',
  icon: IconChevronDown,
};

export const publicNavItems = [
  { key: 'claims', messageKey: 'claimsGlobalNavLink', path: '/claim', name: 'Claims' },
  { key: 'APIs', messageKey: 'apisLink', name: 'APIs', path: '/partner-api' },
  { key: 'Affiliates', messageKey: 'affiliatesLink', name: 'Affiliates', path: '/affiliate-program' },
];
