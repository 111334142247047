import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { StyledTitle, StyledLeadText, StyledCallout, StyledSection, Loading } from '@whitelabel/component-library';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { gray as styleGray, themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { plainLinkStyle } from '@whitelabel/xcover-shared/helpers/style/utils';

export const StyledLayout = styled.div<{ $rtl: boolean; $gray?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 0;
  direction: ${({ $rtl }) => ($rtl ? 'rtl' : 'ltr')};

  ${({ $gray }) =>
    $gray
      ? css`
          background: ${styleGray(200)};
        `
      : css`
          h1${StyledTitle} {
            line-height: ${lineHeight('headingsLg')};
            margin-inline-end: 0.5rem;
            margin-inline-start: 0.5rem;

            ${mediaBreakpointUp('md')`
              margin-inline-end: 1rem;
              margin-inline-start: 1rem;
            `}

            span {
              background: ${themeColor('primary')};
              box-shadow: 0.5rem 0 0 ${themeColor('primary')}, -0.5rem 0 0 ${themeColor('primary')};

              ${mediaBreakpointUp('md')`
                box-shadow: 1rem 0 0 ${themeColor('primary')}, -1rem 0 0 ${themeColor('primary')};
              `};
            }
          }

          ${StyledLeadText} {
            font-family: ${fontFamily('base')};
          }
        `}
`;
export const StyledHeader = styled.header<{ $spacing?: 'lg' }>`
  position: relative;
  z-index: ${zIndex('header')};
  border-bottom: ${getThemeProp('borderWidth')} solid ${styleGray(300)};

  ${mediaBreakpointUp('lg')`
    border-bottom: 0;
  `}

  ${({ $spacing }) =>
    $spacing === 'lg'
      ? css`
          margin-bottom: 1.5rem;

          ${mediaBreakpointUp('md')`
            margin-bottom: 2rem;
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: 3rem;
          `}
        `
      : css`
          margin-bottom: 1rem;

          ${mediaBreakpointUp('md')`
            margin-bottom: 2rem;
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: 3rem;
          `}
        `}

  a {
    text-decoration: none;
    color: initial;

    &:hover {
      ${plainLinkStyle}
    }
  }
`;

export const StyledMain = styled.div<{ $spacing?: 'lg' }>`
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
  }

  ${({ $spacing }) =>
    $spacing === 'lg'
      ? css`
          margin-bottom: 4rem;

          ${mediaBreakpointUp('md')`
          margin-bottom: 5rem;
        `}

          ${mediaBreakpointUp('lg')`
          margin-bottom: 8rem;
        `}

        ${StyledSection} {
            margin-bottom: 4rem;

            ${mediaBreakpointUp('md')`
            margin-bottom: 5rem;
          `}

            ${mediaBreakpointUp('lg')`
            margin-bottom: 8rem;
          `}

          &:last-child {
              margin-bottom: 0;

              ${StyledCallout}:last-child {
                margin-bottom: -4rem;

                ${mediaBreakpointUp('md')`
                margin-bottom: -5rem;
              `}

                ${mediaBreakpointUp('lg')`
                margin-bottom: -8rem;
              `}
              }
            }
          }
        `
      : css`
          margin-bottom: 1.5rem;

          ${mediaBreakpointUp('md')`
            margin-bottom: 2rem;
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: 3rem;
          `}
        `}
`;

export const StyledLoading = styled(Loading)`
  && {
    min-height: ${rem(100)};
    margin-bottom: ${rem(100)};
  }
`;
