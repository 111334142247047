import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { captureExceptionWithFullStory } from '@whitelabel/nextjs-helpers/utils';
import backgroundImg from '@whitelabel/media/images/404-background.jpg';
import { PUBLIC_NAV_ITEMS } from '@whitelabel/xcover-shared/helpers/constants';
import { IError } from '@whitelabel/xcover-shared/helpers/types';
import NavBar from '../NavBar';
import messages from './messages';
import { StyledLayout, StyledHeroBlock } from './stylesErrorPage';

const AUTH_ERROR = 'auth_error';
const FNOL_PATH_KEY = '/account/claims/fnol';
interface IErrorPageProps {
  isNoMatch?: boolean;
  error?: IError;
}

const ErrorPage = ({ isNoMatch = false, error }: IErrorPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { asPath } = useRouter();
  const title = formatMessage(messages.generalErrorTitle);
  const unMatchedBookingOnFNOL = error?.name === AUTH_ERROR && asPath.includes(FNOL_PATH_KEY);
  const leadText = unMatchedBookingOnFNOL
    ? formatMessage(messages.unMatchedBookingText)
    : formatMessage(messages.generalErrorText);
  // to reuse <Heroblock />, need to shape data following this pattern below
  const singleButtonBlock = [
    {
      type: 'buttons',
      value: {
        alignment: 'left',
        buttons: [
          {
            link: '/',
            text: formatMessage(messages.backButtonText),
            variant: 'primary',
          },
        ],
      },
      id: '3c8ce8e9-0ff5-4e88-beb5-1ef737dbceb3',
    },
  ];

  useEffect(() => {
    if (isNoMatch) {
      const err = new Error();
      err.name = 'URL Not Found';
      err.message = `URL: ${asPath} cannot be found`;
      captureExceptionWithFullStory(err);
    }
  }, [isNoMatch, asPath]);

  return (
    <StyledLayout navBar={<NavBar items={PUBLIC_NAV_ITEMS} size="lg" />} spacing="lg">
      <StyledHeroBlock
        id="errorPageHeroBlock"
        title={title}
        leadText={leadText}
        backgroundImage={{ file: backgroundImg }}
        ctaOptions={singleButtonBlock}
      />
    </StyledLayout>
  );
};

export default ErrorPage;
