import React from 'react';
import { useIntl } from 'react-intl';
import LinkTo from '@whitelabel/xcover-shared/components/LinkTo';
import { INavBrandProps } from '@whitelabel/xcover-www-frontend/src/helpers/types';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { StyledBrand, StyledBrandLogo, StyledBrandTitle } from './styledBrand';

const Brand = ({ title, logo, size, link = true, locale, hasLoggedIn }: INavBrandProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledBrand
      {...(link && (hasLoggedIn ? { tag: LinkTo, href: `/${locale}/account` } : { tag: LinkTo, href: `/${locale}` }))}
      data-test-id="NavBarBrand"
    >
      <StyledBrandLogo
        src={logo}
        alt={intl.formatMessage(commonMessages.logo, { brand: title })}
        size={size}
        data-test-id="NavBarBrand-logo"
      />
      <StyledBrandTitle data-test-id="NavBarBrand-title">{title}</StyledBrandTitle>
    </StyledBrand>
  );
};

export default Brand;
