import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { CURRENT_LOCALE } from '@whitelabel/helpers/constants';
import { Modal } from '@whitelabel/component-library';
import { isUSEnglish } from '@whitelabel/helpers/site';
import {
  getBodyContent,
  getLocalsToDisplay,
} from '@whitelabel/xcover-www-frontend/src/components/LocaleModal/helpers';
import { StyledHeading } from '@whitelabel/xcover-www-frontend/src/components/LocaleModal/styledLocaleModal';
import messages from './messages';
import { StyledLoading } from './styledLocaleModal';

interface ILocaleModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const LocaleModal = ({ isOpen, toggle }: ILocaleModalProps): JSX.Element => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { locale: prevLocale, pathname, asPath, query, push } = useRouter();
  const handleLocaleChange = (locale: string) => async () => {
    if (locale !== prevLocale) {
      const newLocale = isUSEnglish(locale) ? 'en-us' : locale;
      sessionStorage.setItem(CURRENT_LOCALE, newLocale);
      setLoading(true);
      await push({ pathname, query }, asPath, { locale: newLocale });
      toggle();
    }
  };
  const localsToDisplay = getLocalsToDisplay();
  const bodyContent = getBodyContent(localsToDisplay, intl.locale, handleLocaleChange);

  return (
    <Modal
      isOpen={isOpen}
      header={<StyledHeading>{intl.formatMessage(messages.header)}</StyledHeading>}
      body={loading ? <StyledLoading /> : bodyContent}
      toggle={toggle}
      backdrop
    />
  );
};

export default LocaleModal;
