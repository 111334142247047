import styled from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { mediaBreakpointDown, mediaBreakpointOnly, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { color, gray, themeColor } from '@whitelabel/helpers/style/colors';
import { srOnly } from '@whitelabel/helpers/style/screenReaders';
import { multiply } from '@whitelabel/helpers/style/units';
import { fontFamily, fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { getRightValue } from '@whitelabel/helpers/style/box';
import { buttonVariant } from '@whitelabel/component-library';

export const StyledDropdownToggle = styled(DropdownToggle)`
  &&& {
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;

    button:focus {
      outline: none;
    }
  }
`;

export const StyledDropdown = styled(UncontrolledDropdown)`
  display: none;
  ${mediaBreakpointUp('lg')`
    padding: 0;
    border-top: 0;
    display: block; 
  `}
`;

export const StyledDropdownCustomer = styled.span`
  display: block;
  padding: 0 1rem;

  ${mediaBreakpointOnly('sm')`
    ${srOnly}
  `}
`;

export const StyledDropdownName = styled.span`
  display: block;
  color: ${themeColor('primary')};
  font-size: ${multiply(fontSize('base'), 1.5)};

  ${mediaBreakpointUp('md')`
    color: inherit;
    font-size: ${multiply(fontSize('base'), 1.125)};
    line-height: ${lineHeight('base')};
  `}
`;

export const StyledDropdownSubText = styled.span`
  display: block;
  color: ${gray(500)};
  font-family: ${fontFamily('serif')};

  ${mediaBreakpointUp('md')`
    color: ${gray(600)};
    font-size: ${multiply(fontSize('base'), 0.875)};
    line-height: ${lineHeight('base')};
  `}
`;

export const StyledDropdownMenu = styled(DropdownMenu)<{ $rtl: boolean }>`
  && {
    min-width: 18.375rem;
    margin-top: 1rem;
    padding: 1rem 0;
  }

  &::before,
  &::after {
    ${getRightValue('0.5625rem')}

    ${mediaBreakpointDown('sm')`
      display: none;
    `}
  }
`;

export const StyledDropdownItem = styled(DropdownItem)`
  && {
    display: flex;
    align-items: center;
    ${buttonVariant('tertiary')}
    padding: 0.25rem 1rem;
  }
`;

export const StyledDropdownItemDivider = styled(DropdownItem).attrs(() => ({ divider: true }))`
  margin: 1rem;
`;

export const StyledDropdownItemIcon = styled.svg.attrs(() => ({ 'aria-hidden': 'true' }))`
  display: block;
  margin-inline-end: 0.5rem;
`;

export const StyledNavIcon = styled.svg`
  display: block;
  margin-inline-end: 0.5rem;
  color: ${color('white')};
`;
