import { defineMessages } from 'react-intl';

export default defineMessages({
  loginLink: {
    id: 'navBar.loginLink',
    defaultMessage: 'Login',
  },
  quoteButton: {
    id: 'navBar.quoteButton',
    defaultMessage: 'Get A Quote',
  },
  logoutLink: {
    id: 'navBar.logoutLink',
    defaultMessage: 'Logout',
  },
  protectionLink: {
    id: 'navbar.protectionLink',
    defaultMessage: 'Protection',
  },
  policiesNavLink: {
    id: 'nav.navbar.policiesNavLink',
    defaultMessage: 'Your protection',
  },
  aboutLink: {
    id: 'nav.navbar.aboutLink',
    defaultMessage: 'About',
  },
  makeClaimLink: {
    id: 'nav.navbar.makeClaimLink',
    defaultMessage: 'Your Claims',
  },
  accountLink: {
    id: 'nav.navbar.accountLink',
    defaultMessage: 'Your Policies',
  },
  policiesLink: {
    id: 'nav.navbar.policiesLink',
    defaultMessage: 'Your Protection',
  },
  travelLink: {
    id: 'nav.navbar.travelLink',
    defaultMessage: 'Travel',
  },
  electronicsLink: {
    id: 'nav.navbar.electronicsLink',
    defaultMessage: 'Electronics',
  },
  appliancesLink: {
    id: 'nav.navbar.appliancesLink',
    defaultMessage: 'Appliances',
  },
  furnitureLink: {
    id: 'nav.navbar.furnitureLink',
    defaultMessage: 'Furniture',
  },
  jewelryLink: {
    id: 'nav.navbar.jewelryLink',
    defaultMessage: 'Jewelry',
  },
  sportsEquipmentLink: {
    id: 'nav.navbar.sportsEquipmentLink',
    defaultMessage: 'Sports Equipment',
  },
  eyewearLink: {
    id: 'nav.navbar.eyewearLink',
    defaultMessage: 'Eyewear',
  },
  ticketEventsLink: {
    id: 'nav.navbar.ticketEventsLink',
    defaultMessage: 'Ticket & Events',
  },
  profileLink: {
    id: 'nav.navbar.profileLink',
    defaultMessage: 'Your profile',
  },
  poweredBy: {
    id: 'nav.navbar.poweredBy',
    defaultMessage: 'Powered by',
  },
  shakeShieldLink: {
    id: 'nav.navbar.shakeShieldLink',
    defaultMessage: 'Shake Shield',
  },
  claimsGlobalNavLink: {
    id: 'nav.navbar.claimsGlobalNavLink',
    defaultMessage: 'Claims',
  },
  helpGlobalNavLink: {
    id: 'nav.navbar.helpGlobalNavLink',
    defaultMessage: 'Help',
  },
  assistGlobalNavLink: {
    id: 'nav.navbar.assistGlobalNavLink',
    defaultMessage: 'XCover Assist',
  },
  accountGlobalNavLink: {
    id: 'nav.navbar.accountGlobalNavLink',
    defaultMessage: 'Account',
  },
  loginGlobalNavLink: {
    id: 'nav.navbar.loginGlobalNavLink',
    defaultMessage: 'Login',
  },
  accountGlobalNavDropdownLink: {
    id: 'nav.navbar.accountGlobalNavDropdownLink',
    defaultMessage: 'Your Policies',
  },
  claimsGlobalNavDropdownLink: {
    id: 'nav.navbar.claimGlobalNavDropdownLink',
    defaultMessage: 'Your Claims',
  },
  profileGlobalNavDropdownLink: {
    id: 'nav.navbar.profileGlobalNavDropdownLink',
    defaultMessage: 'Your Profile',
  },
  logoutGlobalNavDropdownLink: {
    id: 'nav.navbar.logoutGlobalNavDropdownLink',
    defaultMessage: 'Log out',
  },
  protectionNavLink: {
    id: 'nav.navbar.protectionNavLink',
    defaultMessage: 'Protection',
  },
  productHeading: {
    id: 'nav.navbar.productHeading',
    defaultMessage: 'Product',
  },
  businessHeading: {
    id: 'nav.navbar.businessHeading',
    defaultMessage: 'Business',
  },
  travelHeading: {
    id: 'nav.navbar.travelHeading',
    defaultMessage: 'Travel',
  },
  otherHeading: {
    id: 'nav.navbar.otherHeading',
    defaultMessage: 'Other',
  },
  affiliatesLink: {
    id: 'nav.navbar.affiliatesLink',
    defaultMessage: 'Affiliates',
  },
  apisLink: {
    id: 'nav.navbar.apisLink',
    defaultMessage: 'APIs',
  },
  apiAffiliatesLink: {
    id: 'nav.navbar.apiAffiliatesLink',
    defaultMessage: 'API & Affiliates',
  },
  backLink: {
    id: 'navbar.backLink',
    defaultMessage: 'Back',
  },
  appsForMerchants: {
    id: 'nav.navbar.appsForMerchantsLink',
    defaultMessage: 'Merchants',
  },
});
