import styled, { css } from 'styled-components';
import { NavItem, NavLink as BSNavLink } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { themeColor, color, gray } from '@whitelabel/helpers/style/colors';
import {
  mediaBreakpointUp,
  breakpointKeys,
  breakpointNext,
  breakpointInfix,
  mediaBreakpointDown,
} from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

export const StyledNavItem = styled(NavItem)<{ $hideOnMobileAndTablet?: boolean }>`
  padding-inline-start: 0;
  padding-inline-end: 0;
  display: flex;
  font-size: ${fontSize('lg')};

  ${({ $hideOnMobileAndTablet }) =>
    $hideOnMobileAndTablet &&
    css`
      ${mediaBreakpointDown('md')`
          display: none;
        `};
    `}

  &:not(:last-child) {
    margin-bottom: ${spacer};

    ${mediaBreakpointUp('lg')`
      margin-bottom: 0;
    `}
  }
`;

export const StyledNavLink = styled(BSNavLink)`
  padding: 0;
  text-decoration: none;

  &:focus {
    color: ${gray(700)};
  }

  ${({ theme }) =>
    breakpointKeys(theme).map((breakpoint) => {
      const next = breakpointNext(breakpoint)({ theme });

      if (next) {
        const infix = breakpointInfix(next)({ theme });

        return css`
          ${mediaBreakpointUp(next)`
            .navbar-expand${infix} && {
              padding-right: 0;
              padding-inline-start: 0;
            }
          `}
        `;
      }

      return null;
    })}

  ${mediaBreakpointUp('md')`
    margin: ${multiply(spacer, 0.5)};
    margin-inline-start: 0;
  `}

  ${mediaBreakpointUp('lg')`
    margin: 0 ${multiply(spacer, 0.75)};
    font-size: ${multiply(fontSize('base'), 1.125)};
    padding-top: ${multiply(spacer, 0.1875)};
  `}

  .navbar-dark &&&:not(.active) {
    color: ${color('white')};

    ${mediaBreakpointUp('lg')`
      color: ${themeColor('header')};
    `}
  }

  .navbar-dark &&&.active {
    color: ${color('white')};
    ${mediaBreakpointUp('lg')`
      color: ${themeColor('header')};
    `}
  }
`;

export const StyledNavIcon = styled.svg`
  display: block;
  margin-inline-end: ${multiply(spacer, 0.5)};
  color: ${color('white')};
`;
