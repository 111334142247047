import React, { Fragment, ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconChevronLeft } from '@whitelabel/media/icons/small/chevron-left.svg';
import { INavItem } from '@whitelabel/xcover-www-frontend/src/helpers/types';
import NavItem from '../NavItem';
import messages from '../messages';
import {
  StyledNavIcon,
  StyledSecondaryNav,
  StyledNavSeperator,
  StyledBackButton,
  StyledHeading,
  StyledSubHeading,
  StyledNav,
} from './styledSecondaryNav';

interface ISecondaryNavProps {
  items?: INavItem[];
  item: INavItem;
  toggle?: ReactEventHandler;
  onClick?: () => void;
}

const SecondaryNav = ({
  items,
  item: { messageKey: itemMessageKey },
  toggle,
  onClick,
}: ISecondaryNavProps): JSX.Element => {
  const intl = useIntl();
  const renderItem = (item: INavItem) => {
    if (item.children) {
      return (
        <StyledSubHeading data-test-id="SecondaryNav-item-header" data-analytics={`secondaryNav${item.messageKey}`}>
          {intl.formatMessage(messages[item.messageKey as keyof typeof messages])}
        </StyledSubHeading>
      );
    }
    return <NavItem key={item.key} item={item} onClick={onClick} />;
  };

  return (
    <StyledSecondaryNav>
      <StyledBackButton onClick={toggle} type="button">
        <StyledNavIcon $rtl={intl.bidi} as={IconChevronLeft} />
        {intl.formatMessage(messages.backLink)}
      </StyledBackButton>
      <StyledNavSeperator />
      <StyledHeading>{intl.formatMessage(messages[itemMessageKey as keyof typeof messages])}</StyledHeading>
      <StyledNavSeperator />
      {items?.map((item: INavItem) => (
        <Fragment key={item.key}>
          {renderItem(item)}
          <StyledNav>
            {item.children?.map((childItem = item) => (
              <NavItem key={childItem.key} item={childItem} onClick={onClick} />
            ))}
          </StyledNav>
          <StyledNavSeperator />
        </Fragment>
      ))}
    </StyledSecondaryNav>
  );
};

export default SecondaryNav;
